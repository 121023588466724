import React from 'react'
import resolveConfig from 'tailwindcss/resolveConfig'
import tailwindConfig from '../../tailwind.config.js'
import { BreakpointProvider } from './breakpoints'
import { NavigationProvider } from './navigation'

export const wrapRootElement = ({ element }) => {
  let queries = {}

  // Generate an object of Tailwindcss screen sizes to media queries
  const config = resolveConfig(tailwindConfig)
  for (const screen in config.theme.screens) {
    if (config.theme.screens.hasOwnProperty(screen)) {
      const size = config.theme.screens[screen]
      queries[screen] = `(min-width: ${size})`
    }
  }

  return (
    <NavigationProvider>
      <BreakpointProvider queries={queries}>{element}</BreakpointProvider>
    </NavigationProvider>
  )
}
