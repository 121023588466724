import React, { useState, useEffect, createContext, useContext } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import generateUrl from 'utils/generateUrl'

const defaultValue = {}
const NavigationContext = createContext(defaultValue)

const NavigationProvider = ({ children }) => {
  const [navigation, setNavigation] = useState({})

  const data = useNavigationQuery()

  useEffect(() => {
    setNavigation(data.prismicNavigation.data.body)
  }, [data])

  return (
    <NavigationContext.Provider value={navigation}>
      {children}
    </NavigationContext.Provider>
  )
}

function useNavigation() {
  const navigation = useContext(NavigationContext)
  if (navigation === defaultValue) {
    throw new Error('useNavigation must be used within NavigationProvider')
  }

  return navigation
}

function useNavigationItem(uid) {
  const navigation = useContext(NavigationContext)
  if (navigation === defaultValue) {
    throw new Error('useNavigation must be used within NavigationProvider')
  }

  if (Object.keys(navigation).length === 0) return ``

  const url = generateUrl(uid, navigation)

  return url || `/${uid}`
}

function useNavigationLabel(uid) {
  const navigation = useContext(NavigationContext)
  if (navigation === defaultValue) {
    throw new Error('useNavigation must be used within NavigationProvider')
  }

  let label = ``

  if (Array.isArray(navigation)) {
    navigation.forEach(nav => {
      const primaryLinkUid = nav.primary.link.uid
      if (primaryLinkUid === uid) {
        label = nav.primary.label
      }
      nav.items.forEach(navItem => {
        const secondaryLinkUid = navItem.child_link.uid
        if (secondaryLinkUid === uid) {
          label = navItem.child_link_label
        }
      })
    })
  }

  return label
}

function useNavigationQuery() {
  return useStaticQuery(graphql`
    {
      prismicNavigation {
        data {
          body {
            ... on PrismicNavigationBodyNavItem {
              primary {
                link {
                  uid
                }
                label
              }
              items {
                child_link {
                  uid
                }
                child_link_label
              }
            }
          }
        }
      }
    }
  `)
}

export {
  NavigationProvider,
  useNavigation,
  useNavigationItem,
  useNavigationLabel,
  useNavigationQuery,
}
