/**
 * Generates a url from a page UID and navigation data
 * @author Josh Smith <josh@batch.nz>
 * @param  array  navigation An array of navigation data
 * @param  string uid        Page UID
 * @return array             An array of breadcrumbs
 */
function generateUrl(uid, navigation) {
  if (!Array.isArray(navigation)) return uid

  let url = ``
  navigation.forEach(nav => {
    const primaryLinkUid = nav.primary.link.uid
    if (primaryLinkUid === uid) {
      url = `/${primaryLinkUid}`
    }
    nav.items.forEach(navItem => {
      const secondaryLinkUid = navItem.child_link.uid
      if (secondaryLinkUid === uid) {
        url = `/${primaryLinkUid}/${secondaryLinkUid}`
      }
    })
  })
  return url
}

module.exports = generateUrl
