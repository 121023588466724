/**
 * * To overwrite the default styles
 * https://github.com/tailwindcss/tailwindcss/blob/master/stubs/defaultConfig.stub.js
 */

module.exports = {
  theme: {
    fontFamily: {
      sans: [
        'sofia-pro',
        'Inter',
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        '"Noto Sans"',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
        '"Noto Color Emoji"',
      ],
    },
    fontSize: {
      xs: '0.8125rem', // 13px
      sm: '0.875rem', // 14px
      base: '1rem', // 16px
      lg: '1.125rem', // 18px
      xl: '1.3125rem', // 21px
      '2xl': '1.75rem', // 28px
      '3xl': '2.3125rem', // 37px
      '4xl': '2.625rem', // 42px
      '5xl': '3.875rem', // 62px
    },
    colors: {
      black: 'black',
      transparent: 'transparent',
      white: '#FFFFFF',
      cream: '#FAF6F3',
      sand: '#F1ECE9',
      'sand-dark': '#E3D8D0',
      'light-grey': '#D8D8D8',
      grey: '#999999',
      charcoal: '#222222',
      teal: 'hsl(181, 83%, 40%)',
      'teal-alt': 'hsl(181, 83%, 45%)', // +5% lightness
      'teal-opaque-75': 'hsla(181, 83%, 40%, 0.75)',
      blue: 'hsl(204, 100%, 39%)',
      'blue-alt': ' hsl(204, 100%, 44%)', // +5% lightness
      peach: 'hsl(4, 93%, 77%)',
      'peach-opaque-90': 'hsla(4, 93%, 77%, 0.90)',
      'purple-opaque-75': 'hsla(272, 22%, 61%, 0.75)',
      phoneAlt: '#0077c7',
    },
    boxShadow: {
      default: '0 1px 2px 0 rgba(0,0,0,0.20), 0 1px 3px 0 rgba(0,0,0,0.10)',
      md: '0 3px 6px 0 rgba(0,0,0,0.08), 0 3px 6px 0 rgba(0,0,0,0.12)',
      lg: ' 0 6px 6px 0 rgba(0,0,0,0.08), 0 10px 20px 0 rgba(0,0,0,0.15)',
      xl: '0 10px 10px 0 rgba(0,0,0,0.11), 0 14px 28px 0 rgba(0,0,0,0.15)',
      '2xl': '0 15px 12px 0 rgba(0,0,0,0.16), 0 19px 38px 0 rgba(0,0,0,0.10)',
      none: 'none',
    },
    extend: {
      spacing: {
        '9': '2.25rem',
        '7': '1.75rem',
        '11': '2.75rem',
        '14': '3.5rem',
        '15': '3.75rem',
        '18': '4.5rem',
        '22': '5.5rem',
        '30': '7.5rem',
        '35': '8.75rem',
        '75': '18.75rem',
        '128': '32.5rem',
        '148': '36rem',
        '192': '48rem',
        '195': '48.75rem',
      },
      opacity: {
        '60': '0.60',
      },
      screens: {
        '2xl': '1366px',
      },
      maxWidth: {
        '2xs': '18rem',
        '75': '75%',
      },
      maxHeight: {
        '0': '0',
        'mobile-nav': '50rem',
      },
      minWidth: {
        'content-button': '14rem',
      },
      height: {
        '11': '2.75rem',
      },
    },
  },
  variants: {
    margin: ['responsive', 'last'],
    width: ['responsive', 'last'],
  },
}
